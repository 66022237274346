






























































































































































































































































































































































































































































































































































































































.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.Score-ruleForm {
  .el-form-item {
    display: flex;
    align-items: center;
  }
}
